@font-face {
  font-family: "Artisan12";
  src: local("Artisan12"),
   url("./fonts/artisan12.ttf") format("truetype");
}

@font-face {
  font-family: "EnglishTowne";
  src: local("EnglishTowne"),
  url("./fonts/EnglishTowne.ttf") format("truetype");
}


@font-face {
  font-family: "EBGaramond";
  src: local("EBGaramond"),
  url("./fonts/eb-garamond.12-regular.ttf") format("truetype");
}

.font-artisan {
  font-family: "Artisan12";
}

.font-english-towne {
  font-family: "EnglishTowne";
}

html {
  position: relative;
  min-height: 100%;
}

html, body {
  font-family: "EBGaramond", "Garamond", "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  display: flex;
  flex-direction: column;
  color: rgb(var(--foreground-rgb));
  background: repeating-radial-gradient(farthest-corner at 5% 10%,
    rgb(var(--background-start-rgb)) 0, 
    rgb(var(--background-end-rgb)), 
    rgb(var(--background-start-rgb)) 20%
  );
  background-attachment: fixed;
  height: 100%;
}

button, input {
  box-shadow: 1px 1px 4px black;
}

:root {
  --bs-nav-link-color: 220, 220, 220;
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 31, 32, 32;
  --background-end-rgb:   33, 35, 35;
}

.h-83 {
  height: 83%;
}

.fs-7 {
  font-size: 0.85rem;
}

button.nav-link {
  color: red;
}

button.nav-link:hover {
  color: darkslategrey;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: inset 4px 4px 24px rgb(0 0 0 / 50%), 4px 4px 24px rgb(0 0 0 / 15%);
  background-color: rgb(var(--background-start-rgb)) !important;
  padding-top: 0px;
  padding-bottom: 0px;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
